import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox, videoBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";
import { Link } from "gatsby";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					Discover the 5 Often-Forgotten Essentials for Your Summer Party Boat
					Cruise | Tampa Bay Boating Adventures
				</title>
				<meta
					name="description"
					content="From mineral sunscreen to energy drinks, discover the 5 essential yet often-forgotten items that will make your voyage a blast. We've got you covered for the ultimate sun-soaked adventure. Don't miss out on the key ingredients to a perfect day on the water!"
				/>
				<meta name="keywords" content="Bottlegirl, Professional Model, Tips" />
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="Bottle girl with guests on the charter"
									src="../../images/blog/bottlegirl-with-guests-on-charter.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>
										Discover the 5 Often-Forgotten Essentials for Your Summer
										Party Boat Cruise
									</h1>
									<p>March 04, 2023</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<p>
											The warm weather has arrived and summer is in full swing.
											There is really no better way to celebrate the season than
											by heading out on a boat for a Summer Party Boat Cruise.
											As we are the experts in cruising around Tampa Bay, here
											is our list of 5 important essentials to bring with you on
											your trip – trust us, you’ll thank us!
										</p>
										<div className={pageContentMedia}>
											<div className={videoBox}>
                                            <iframe
												width="100%"
												height="375"
												src="https://youtube.com/embed/CNflztl2gTk"
												title="YouTube video player"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                                                            gyroscope; picture-in-picture;
                                                            web-share"
												allowfullscreen
											></iframe>
                                            </div>
										</div>
										<h2>1. Mineral Sunscreen</h2>
										<p>
											One of the most important things to take on a summer
											party. A boat cruise is an essential that many people
											forget and live to regret. Sunscreen is extremely
											important, especially in Florida and with a hot summer
											sun. Sunscreen is used to prevent sun damage and sunburn
											can also help reduce early aging. If you have tattoos,
											suncream is even more important to ensure that your tattoo
											stays in good condition. When out on the water, we
											recommend a mineral suncream instead of a chemical one.
											This is because chemical sunscreens contain harsh
											ingredients such as avobenzone and oxybenzone which can be
											toxic to marine life and damaging to our oceans. Try and
											avoid sunscreens that come in a spray bottle, as these can
											often spell suncream onto the deck of the boat, making it
											slippery and hazardous.
										</p>
										<hr />
										<h2>2. Non-Alcoholic Beverages</h2>
										<p>
											We get it – when you’re out on a summer boat party cruise
											you want to enjoy yourself and have fun. Therefore, it is
											understandable that everyone remembers to bring their
											favorite alcohol such as beer, liquor, or wine. While you
											should definitely do this, you should also remember to
											bring non-alcoholic drinks to use as a mixer or to simply
											keep yourself hydrated throughout the day. In the hot
											summer heat, staying hydrated is so important and it will
											ensure that you enjoy your day even more. Having a number
											of different non-alcoholic drinks also means that you can
											create new cocktail combinations, as they say, goes on –
											what fun!
										</p>
										<hr />
										<h2>3. Energy Drinks</h2>
										<p>
											Partying in the hot sun can also make you tired, and you
											can become exhausted pretty easily – trust us, we’ve seen
											a fair few party-goers have to take a little afternoon
											nap. Instead of this, make sure you bring a little
											pick-me-up in the form of an energy drink as this will
											help give you the energy boost that you need. To stop
											yourself from having the inevitable sugar crash go for the
											sugar-free options!
										</p>
										<hr />
										<h2>4. Energy Drinks</h2>
										<p>
											While enjoying their day out on a party boat, many people
											are fond of having a little smoke on a cigar, or even on a
											disposable nicotine vape. Imagine lying back in the
											sunlight with a drink in one hand and a cigar in the other
											and not a care in the world. You don’t want to be the one
											that’s forgotten their nicotine hit when you are already
											aboard so make sure that you come prepared. Our top
											recommendations on where to source some great cigars are
											Ybor’s famous King Corona Café or Nicahabana for a
											handmade classic Tampa cigar.
										</p>
										<hr />
										<h2>5. Book a Bottlegirl</h2>
										<p>
											Last but not least on our list is something that you may
											not have thought of, but we highly recommend it! We have
											seen thousands of trips as part of our job, and let us
											tell you- the ones that hire a bottle girl to serve them
											drinks and wait on their requests are the boat trips that
											are the most enjoyable. Florida has a great selection of
											bottle girls to choose from, who are known for their
											excellent service after their experience working in
											Tampa’s hot nightclubs. On top of simply serving normal
											drinks, the bottle girl can serve you when you are in the
											water or chilling on one of the region’s famous sandbars.
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
